import React, { Component } from "react";
import logo from "../../assets/img/white_logo.png";
import { Link, withRouter } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { Container, Row, Col, Visible, Hidden } from "react-grid-system";

const iosScrollListener = callBack => {
  const element = document.createElement('div');
  element.id = 'hidden';
  element.style='position: absolute; opacity: 0';
  document.body.append(element);

  const fn = () => {
    element.innerHTML = window.scrollY;
    callBack();
  }
  window.addEventListener('scroll', fn);

  return () => window.removeEventListener('scroll', fn);
}
class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //homeActive:false,
      activeItem: -1,
      menuOpen: false,
      isTop: true,
    };
  }

  getHash = key => key.split(" ").join("").toLowerCase();

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }
  // hideMenu = () =>{
  //   window.scroll(0,0);
  //   this.setState({
  //     menuOpen: false,
  //     homeActive:true
  //   });
  // }
  handleRouteChange = index => {
    if (this.props.location.pathname.includes("properties")) {
      this.setState({
        menuOpen: false,
        homeActive: false
      });
      this.props.history.push({
        pathname: "/",
        hash: "#" + this.getHash(this.props.items[index])
      });
    } else {
      this.setState(
        {
          activeItem: index,
          menuOpen: false,
          homeActive: false
        },
        () => {
          this.props.history.push({
            hash: "#" + this.getHash(this.props.items[index])
          });
        }
      );
    }
  };

  componentDidUpdate() {
    if (
      this.props.location.pathname.includes("/properties") &&
      this.state.activeItem !== -1
    ) {
      this.setState({
        activeItem: -1
      });
    }
  }
  isMenuOpen = function(state) {
    console.log(state);
    return state.isOpen;
  };
  componentDidMount() {
    this.remove = iosScrollListener(() => {
      const offset = this.props.location.pathname === '/properties' ? 50 : 200;
      if (!this.headerRef) {
        return;
      }
      if (window.scrollY > offset) {
        this.headerRef.classList.add('active');
      } else {
        this.headerRef.classList.remove('active');
      }
    });
  }
  componentWillUnmount() {
    this.remove();
  }
  render() {
    var styles = {
      bmBurgerButton: {
        position: "relative",
        width: "23px",
        height: "20px",
        left: "0",
        top: "4px"
      },
      bmBurgerBars: {
        background: "#fff",
        height: "12%"
      },
      bmBurgerBarsHover: {
        background: "#a90000"
      },
      bmCrossButton: {
        height: "24px",
        width: "24px",
        top: "16px",
        right: "17px"
      },
      bmCross: {
        background: "#ffffff",
        width: "3px",
        height: "20px"
      },
      bmMenuWrap: {
        position: "fixed",
        height: "100%",
        top: "0px",
        left: "-15px",
        width: "285px"
      },
      bmMenu: {
        background: "#061223  ",
        padding: "2.5em 1.5em 0",
        fontSize: "1.15em"
      },
      bmMorphShape: {
        fill: "#373a47"
      },
      bmItemList: {
        color: "#b8b7ad",
        marginTop: "55px",
        marginLeft: "30px"
      },
      bmItem: {
        display: "inline-block",
        width: "100%",
        color: "#ffffff",
        outline: "none",
        opacity: "0.7",
        marginBottom: "25px"
      },

      bmOverlay: {
        background: "rgba(0, 0, 0, 0.7  )",
        top: 0
      }
    };
    return (
      <Container fluid style={{ maxWidth: "1200px" }}>
        <Visible xs>
          <div ref={r => {this.headerRef = r}} className="header">
            <Row>
              <Col xs={6}>
                <div className="menu">
                  <Menu
                    styles={styles}
                    isOpen={this.state.menuOpen}
                    onStateChange={state => this.handleStateChange(state)}
                  >
                    {/* <Link to="/" 
                    className={
                        this.state.homeActive
                          ? "menu-item active"
                          : "menu-item "
                      } 
                    onClick={() => this.hideMenu()}>
                      Home
                    </Link> */}
                    {this.props.items.map((item, index) => (
                      <a
                        key={index}
                        href="Javascript:void(0)"
                        className={
                          this.state.activeItem === index
                            ? "menu-item active"
                            : "menu-item "
                        }
                        onClick={() => this.handleRouteChange(index)}
                      >
                        {item}
                      </a>
                    ))}
                    <a
                      href="Javascript:void(0)"
                      className="menu-item"
                      onClick={this.props.openModal}
                    >
                      Contact us
                    </a>
                  </Menu>
                </div>
              </Col>
              <Col xs={6}>
                <Link to="/" className="logo">
                  <img src={logo} alt="logo" />
                </Link>
              </Col>
            </Row>
          </div>
        </Visible>
        <Hidden xs>
          <div className="header">
            <Row>
              <Col sm={2}>
                <Link to="/" className="logo">
                  <img src={logo} alt="logo" />
                </Link>
              </Col>
              <Col sm={10}>
                <div className="nav">
                  <ul>
                    {this.props.items.map((item, index) => (
                      <li key={index}>
                        <a
                          rel="noopener noreferrer"
                          href="Javascript:void(0)"
                          className={
                            this.state.activeItem === index ? "active" : ""
                          }
                          onClick={() => this.handleRouteChange(index)}
                        >
                          {item}
                        </a>
                      </li>
                    ))}
                    <li>
                      <a
                        href="Javascript:void(0)"
                        onClick={this.props.openModal}
                      >
                        Contact us
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Hidden>
      </Container>
    );
  }
}
export default withRouter(Header);
