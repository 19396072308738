/* eslint-disable react/jsx-no-target-blank */
import React, { Component } from "react";
import Swiper from "react-id-swiper";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Visible,
  Hidden,
  setConfiguration
} from "react-grid-system";
import scrollToComponent from "react-scroll-to-component";
import { ReactComponent as Share } from "../../assets/img/Share.svg";
import { ReactComponent as Connect } from "../../assets/img/Connect.svg";
import { ReactComponent as Discover } from "../../assets/img/Discover.svg";
import { Autoplay } from "swiper/dist/js/swiper.esm";
import Video from "../../assets/img/gh.mp4";
import MobileVideo from "../../assets/img/mobile.mp4";
const axios = require("axios");

setConfiguration({ breakpoints: [576, 769, 992, 1200] });

class Home extends Component {
  constructor(props) {
    super(props);
    this.home = React.createRef();
    this.whyusRef = React.createRef();
    this.infoRef = React.createRef();
    this.swiperRef = React.createRef();
    this.testimonialRef = React.createRef();
    this.teamRef = React.createRef();
    this.listener = props.history.listen(location => {
      if (location.hash) {
        const item = location.hash.replace("#", "");
        const index = props.items.indexOf(item);
        console.log(index);
        this.scroll(index, this.myRefs[index]);
      }
    });
    this.state = {
      swiper: null,
      disableNextButton: false,
      disablePrevButton: true,
      properties: [],
      city: []
    };
  }

  scroll = (index, ref) => {
    // ref.current.scrollIntoView({ behavior: "smooth" });
    scrollToComponent(ref.current, {
      offset: -80,
      align: "top",
      duration: 500
    });
  };

  getProperties() {
    const headers = {
      "device-unique-id": "8hfjdhf84r84kdhdh"
    };
    const params =
          {
            offset:0,
            total:6,
          };
    axios
      .get(`https://services.guesthouser.com/v1.6/prive/listings`, {
        headers,
        params
      })
      .then(response => {
        //console.log("sfs", response);
        this.setState({
          properties: response.data.data.properties,
          city: response.data.data.city,
        });
      })
      .catch(error => {
        //console.log(error);
      });
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getProperties();
    this.myRefs = [
      this.home,
      this.whyusRef,
      this.infoRef,
      this.swiperRef,
      this.testimonialRef,
      this.teamRef
    ];

    if (this.props.location.hash) {
      const item = this.props.location.hash.replace("#", "");
      console.log(item, this.props.items);
      const index = this.props.items.indexOf(item);
      console.log(index);
      setTimeout(() => {
        this.scroll(index, this.myRefs[index]);
      }, 200);
    }
  }

  componentWillUnmount() {
    this.listener();
  }

  goNext = () => {
    if (this.state.swiper !== null) {
      this.state.swiper.slideNext();
    }
  };

  goPrev = () => {
    if (this.state.swiper !== null) {
      this.state.swiper.slidePrev();
    }
  };

  render() {
    const params = {
      slidesPerView: 2,
      freeMode: true,
      slidesOffsetAfter: 16,
      breakpoints: {
        769: {
          slidesPerView: 2.3,
          spaceBetween: 12
        },
        575: {
          slidesPerView: 1.2,
          spaceBetween: 12
        }
      },
      on: {
        slideChange: () => {
          console.log('index',this.state.swiper.activeIndex);
          if (
            this.state.properties.length - 2  ===
            this.state.swiper.activeIndex
          ) {
            this.setState({
              disableNextButton: true
            });
          } else if (this.state.swiper.activeIndex === 0) {
            this.setState({
              disablePrevButton: true
            });
          } else {
            this.setState({
              disablePrevButton: false,
              disableNextButton: false
            });
          }
        }
      },
      spaceBetween: 30
    };

    const param = {
      modules: [Autoplay],
      slidesPerView: 1,
			spaceBetween: 0,
			autoplay: {
        delay: 2500,
        disableOnInteraction: false,
			},	
      breakpoints: {
        769: {
          slidesPerView: 1
        }
      }
    };
    const ellipsis = {
      WebkitBoxOrient: "vertical"
    };
    return (
      <div>
        <Hidden xs>
          <div className="cenemagraph" ref={this.home}>
            <video
              src={Video}
              id="video-bg"
              autoPlay
              loop
              muted
              webkit-playsinline="true"
              playsinline="true"
            />
          </div>
        </Hidden>
        <Visible xs>
          <div className="cenemagraph-mobile" ref={this.home}>
            <video
              src={MobileVideo}
              id="video-bg"
              autoPlay
              loop
              muted
              webkit-playsinline="true"
              playsinline="true"
            />
            <div className="slogan">
              <h1>Holidays made simple</h1>
              <h3>
                We provide end-to-end holiday home management and marketing{" "}
                services, free of any hassles, headaches, or hidden costs.
              </h3>
              <button onClick={this.props.openModal}>List your home</button>
            </div>
          </div>
        </Visible>
        <Container className="wrapper" fluid style={{ maxWidth: "1200px" }}>
          <Hidden xs>
            <Row>
              <Col xs={12} sm={12}>
                {/******************* why us? ********************/}
                <div className="slogan">
                  <h1>Holidays made simple</h1>
                  <h3>
                    We provide end-to-end holiday home management and marketing{" "}
                    <br />
                    services, free of any hassles, headaches, or hidden costs.
                  </h3>
                  <button onClick={this.props.openModal}>List your home</button>
                </div>
              </Col>
            </Row>
          </Hidden>

          {/******************* why us? ********************/}
          <div className="whyus" ref={this.whyusRef}>
            <Row>
              <Col sm={12}>
                <div className="heading">
                  Have your home managed professionally &amp; earn extra income!
                </div>
                <div className="subheading" />
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="info">
                  <div className="icon icon1" />
                  <div className="title">Complete home care</div>
                  <div className="content">
                    Our comprehensive cleaning processes and operating
                    procedures coupled with regular staff training ensure
                    superior quality end-to-end home maintenance. From laundry
                    to upkeep and repairs, we’ve got you covered.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="info">
                  <div className="icon icon2" />
                  <div className="title">Second source of income</div>
                  <div className="content">
                    Seasoned property managers, professional housekeepers, and
                    data-driven revenue managers run your second home on par
                    with five-star-hotel standards and guarantee you a steady
                    flow of handsome passive income.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="info">
                  <div className="icon icon3" />
                  <div className="title">Peace of mind</div>
                  <div className="content">
                    24x7 customer care and dedicated relationship managers
                    ensure all booking requests are serviced efficiently. A
                    user-friendly dashboard gives you complete visibility on
                    your earnings, home performance, and maintenance schedules.
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/******************* How? ********************/}
          <div className="info" ref={this.infoRef}>
            <Row>
              <Col xs={12} sm={12}>
                <div className="info-container">
                  <div className="heading">
                    Experts from diverse fields collaborate to deliver unique
                    solutions.
                  </div>
                  <div className="subheading">
                    Technology, hospitality, and business development come
                    together to deliver unique solutions.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="box black">
                  <div className="icon">
                    <Connect />
                  </div>
                  <div className="title">Technology</div>
                  <div className="subtitle">
                    A full-stack engineering team creatively utilizes technology
                    to design solutions that simplify your operational needs.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="box blue">
                  <div className="icon">
                    <Share />
                  </div>
                  <div className="title">Hospitality</div>
                  <div className="subtitle">
                    Seasoned professionals from leading hospitality brands
                    guarantee comprehensive property management.
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="box green">
                  <div className="icon">
                    <Discover />
                  </div>
                  <div className="title">Business Development</div>
                  <div className="subtitle">
                    A strategic alliance with{" "}
                    <a href="https://www.guesthouser.com" target="_blank">
                      GuestHouser
                    </a>{" "}
                    provides visibility across a vast network of direct and
                    distributed visitors.
                  </div>
                </div>
              </Col>
            </Row>
          </div>

          {/******************* Portfolio Homes ********************/}
          {this.state.properties.length > 0 && (
            <div className="info" ref={this.swiperRef}>
              <Row>
                <Col xs={12} sm={12} md={10}>
                  <div className="info-container">
                    <div className="heading">
                      Private residences managed professionally by Properly
                    </div>
                    <div className="subheading">
                      We thought you might wanna have a sneak peak
                    </div>
                  </div>
                </Col>
                <Hidden xs>
                  <Col xs={12} sm={12} md={2}>
                    <div className="seeall">
                      <Link to="/properties">Show all</Link>
                    </div>
                  </Col>
                </Hidden>
              </Row>
              <Row>
                <div className="swiper-outer-wrap">
                  <Swiper
                    {...params}
                    getSwiper={swiper => this.setState({ swiper })}
                  >
                    {this.state.properties.map((item, index) => {
                      return (
                        <a
                          rel="noopener noreferrer"
                          href={item.url}
                          target="_blank"
                          key={index}
                        >
                          <div className="slider-container">
                            <div
                              className="slide-image"
                              style={{
                                backgroundImage: `url(${
                                  item.properties_images[0].image
                                })`
                              }}
                            />
                            <div className="content">
                              <div className="heading" style={ellipsis}>
                                {item.property_title}
                              </div>
                              <div className="subheading">
                                {item.area}, {item.state}
                              </div>
                            </div>
                          </div>
                        </a>
                      );
                    })}
                  </Swiper>
                  <a
                    href="Javascript:void(0)"
                    onClick={this.goPrev}
                    className={`swiper-prev-btn ${this.state
                      .disablePrevButton && "disabled"}`}
                  />
                  <a
                    href="Javascript:void(0)"
                    onClick={this.goNext}
                    className={`swiper-next-btn ${this.state
                      .disableNextButton && "disabled"}`}
                  />
                </div>
                <Visible xs>
                  <div className="seeall">
                    <Link to="/properties">Show all</Link>
                  </div>
                </Visible>
              </Row>
            </div>
          )}
          {/******************* testimonials ********************/}
          <div className="info" ref={this.testimonialRef}>
            <Row>
              <Col xs={12} sm={12}>
                <div className="info-container">
                  <div className="heading">Testimonials</div>
                  <div className="subheading">
                    Here’s what our home owners have to say about our services.
                  </div>
                </div>
              </Col>
            </Row>

            <div className="testmonial-outer-wrap">
              <Swiper {...param}>
                <Row>
                  <Col xs={12} sm={12} md={4}>
                    <div className="testimonials">
                      <div className="image image1">
                        {/* <div className="icon" /> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={7}>
                    <div className="testimonials">
                      <div className="text">
                        <Hidden xs>
                          <span className="up">&#10077;</span>
                        </Hidden>
                        The cleaning and maintenance at my property has
                        benefited me and my income from the property has
                        increased. I am happy with the team of GuestHouser. Very
                        helpful, kind, energetic, and hardworking people. <br />
                        <strong>I wish the very best for them.</strong>
                        <Hidden xs>
                          <span className="down">&#10078;</span>
                        </Hidden>
                      </div>
                      <div className="name">
                        <strong>Mary Fernandes</strong> <br />
                        Harmony Guesthouse, Candolim, Goa{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={4}>
                    <div className="testimonials">
                      <div className="image image2">
                        {/* <div className="icon" /> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={7}>
                    <div className="testimonials">
                      <div className="text">
                        <Hidden xs>
                          <span className="up">&#10077;</span>
                        </Hidden>
                        I gave my place to GuestHouser in November 2018 and so
                        far I am happy to have them take care of my home and
                        generate income for me. I am happy with the team for
                        responding immediately to my queries.
                        <br />
                        <strong>God bless you all.</strong>
                        <Hidden xs>
                          <span className="down">&#10078;</span>
                        </Hidden>
                      </div>
                      <div className="name">
                        <strong>Loretta Joseph</strong> <br />
                        Skylark Apartments, Alto Porvorim, Goa{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={4}>
                    <div className="testimonials">
                      <div className="image image3">
                        {/* <div className="icon" /> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={7}>
                    <div className="testimonials">
                      <div className="text">
                        <Hidden xs>
                          <span className="up">&#10077;</span>
                        </Hidden>
                        Properly has reduced headaches for me in the form of
                        cleaning, managing and maintaining my property. I am
                        happy to see the steady inflow of income compared to the
                        expense of maintaining an empty home.
                        <Hidden xs>
                          <span className="down">&#10078;</span>
                        </Hidden>
                      </div>
                      <div className="name">
                        <strong>Anil Chopra</strong> <br />
                        Chopra Villa, Nerul,Goa{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={4}>
                    <div className="testimonials">
                      <div className="image image4">
                        {/* <div className="icon" /> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={7}>
                    <div className="testimonials">
                      <div className="text">
                        <Hidden xs>
                          <span className="up">&#10077;</span>
                        </Hidden>
                        Properly has reduced my headache of maintaining my
                        property. I know it is in safe hands and proper care
                        will be taken towards cleaning and managing it well. I
                        wish them all the luck. Keep up the good work!
                        <Hidden xs>
                          <span className="down">&#10078;</span>
                        </Hidden>
                      </div>
                      <div className="name">
                        <strong>
                          {" "}
                          <br />
                          Rosyn Domello
                        </strong>{" "}
                        <br />
                        Siolim, Goa{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12} md={4}>
                    <div className="testimonials">
                      <div className="image image5">
                        {/* <div className="icon" /> */}
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={7}>
                    <div className="testimonials">
                      <div className="text">
                        <Hidden xs>
                          <span className="up">&#10077;</span>
                        </Hidden>
                        Properly has certainly reduced headaches. I do not worry
                        about the property since it is in safe hands. I have not
                        received any complaints except for some minor issues. I
                        believe Properly is doing a great job.
                        <Hidden xs>
                          <span className="down">&#10078;</span>
                        </Hidden>
                      </div>
                      <div className="name">
                        <strong>Madhusudan Bhaskaran</strong> <br />
                        Humming Woods, Vagator, Goa{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Swiper>
            </div>
          </div>

          {/******************* Our Team ********************/}
          <div className="info" ref={this.teamRef}>
            <Row>
              <Col xs={12} sm={12}>
                <div className="info-container">
                  <div className="heading">The Core Team</div>
                  {/* <div className="subheading">
                    Meet the driving force behind Properly!
                  </div> */}
                </div>
              </Col>

              {/* for mobile view */}
              <Visible xs>
                <a
                  rel="noopener noreferrer"
                  href="https://www.linkedin.com/in/dhruvdsharma"
                  target="_blank"
                >
                  <div className="view-box">
                    <Row>
                      <Col xs={5}>
                        <div className="image image1" />
                      </Col>
                      <Col xs={6}>
                        <div className="content">
                          <div className="heading">Dhruv Sharma</div>
                          <div className="subheading">Founder &amp; CEO</div>
                          <div className="text" style={ellipsis}>
                            A Boston graduate and a Forbes 30 under 30 alumni,
                            Dhruv founded GuestHouser to connect discerning
                            travellers with owners of vacant second homes. With
                            Properly, Dhruv aims to pioneer professional holiday
                            home management.
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://in.linkedin.com/in/arpit-pant-60618a27"
                  target="_blank"
                >
                  <div className="view-box">
                    <Row>
                      <Col xs={5}>
                        <div className="image image2" />
                      </Col>
                      <Col xs={6}>
                        <div className="content">
                          <div className="heading">Arpit Pant</div>
                          <div className="subheading">
                            General Manager, Properly
                          </div>
                          <div className="text" style={ellipsis}>
                            With over 18 years of experience in leading highly
                            accomplished individuals and teams in hotel
                            development and hotel operations, Arpit brings
                            unparalleled operational excellence and executive
                            capability to Properly.
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://in.linkedin.com/in/guido-van-steijn-57415a96/%7Bcountry%3Dus%2C+language%3Den%7D"
                  target="_blank"
                >
                  <div className="view-box">
                    <Row>
                      <Col xs={5}>
                        <div className="image image3" />
                      </Col>
                      <Col xs={6}>
                        <div className="content">
                          <div className="heading">Guido van Steijn</div>
                          <div className="subheading">
                            Chief Operating Officer
                          </div>
                          <div className="text" style={ellipsis}>
                            Having built the sales team of a health education
                            company in Netherlands at 16 and becoming the COO of
                            GuestHouser at 20, Guido aims to use his growth
                            hacking and team building skills to build a
                            hyper-scalable infrastructure at Properly.
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </a>
                <a
                  rel="noopener noreferrer"
                  href="https://in.linkedin.com/in/shirin-sharma-906b10121"
                  target="_blank"
                >
                  <div className="view-box">
                    <Row>
                      <Col xs={5}>
                        <div className="image image4" />
                      </Col>
                      <Col xs={6}>
                        <div className="content">
                          <div className="heading">Shirin Sharma</div>
                          <div className="subheading">Director of HR</div>
                          <div className="text" style={ellipsis}>
                            From being a summer intern in the company to heading
                            the recruitment efforts at GuestHouser, Shirin is
                            now charged with meeting the scaling Properly’s ever
                            increasing human resource requirements.
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </a>
              </Visible>

              {/* for desktop view */}
              <Hidden xs>
                <Col sm={6} md={4}>
                  <a
                    href="https://www.linkedin.com/in/dhruvdsharma"
                    target="_blank"
                    className="view-box"
                  >
                    <div className="image image1" />
                    <div className="content">
                      <div className="heading">Dhruv Sharma</div>
                      <div className="subheading">Founder &amp; CEO</div>
                      <div className="text" style={ellipsis}>
                        A Boston graduate and a Forbes 30 under 30 alumni, Dhruv
                        founded GuestHouser to connect discerning travellers
                        with owners of vacant second homes. With Properly, Dhruv
                        aims to pioneer professional holiday home management.
                      </div>
                    </div>
                  </a>
                </Col>
                <Col sm={6} md={4}>
                  <a
                    href="https://in.linkedin.com/in/arpit-pant-60618a27"
                    target="_blank"
                    className="view-box"
                  >
                    <div className="image image2" />
                    <div className="content">
                      <div className="heading">Arpit Pant</div>
                      <div className="subheading">
                        General Manager, Properly
                      </div>
                      <div className="text" style={ellipsis}>
                        With over 18 years of experience in leading highly
                        accomplished individuals and teams in hotel development
                        and hotel operations, Arpit brings unparalleled
                        operational excellence and executive capability to
                        Properly.
                      </div>
                    </div>
                  </a>
                </Col>
                <Col sm={6} md={4}>
                  <a
                    href="https://in.linkedin.com/in/guido-van-steijn-57415a96/%7Bcountry%3Dus%2C+language%3Den%7D"
                    target="_blank"
                    className="view-box"
                  >
                    <div className="image image3" />
                    <div className="content">
                      <div className="heading">Guido van Steijn</div>
                      <div className="subheading">Chief Operating Officer</div>
                      <div className="text" style={ellipsis}>
                        Having built the sales team of a health education
                        company in Netherlands at 16 and becoming the COO of
                        GuestHouser at 20, Guido aims to use his growth hacking
                        and team building skills to build a hyper-scalable
                        infrastructure at Properly.
                      </div>
                    </div>
                  </a>
                </Col>
                <Col sm={6} md={4}>
                  <a
                    href="https://in.linkedin.com/in/shirin-sharma-906b10121"
                    target="_blank"
                    className="view-box"
                  >
                    <div className="image image4" />
                    <div className="content">
                      <div className="heading">Shirin Sharma</div>
                      <div className="subheading">Director of HR</div>
                      <div className="text" style={ellipsis}>
                        From being a summer intern in the company to heading the
                        recruitment efforts at GuestHouser, Shirin is now
                        charged with meeting the scaling Properly’s ever
                        increasing human resource requirements.
                      </div>
                    </div>
                  </a>
                </Col>
              </Hidden>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default Home;
