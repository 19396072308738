import React, { Component } from "react";
import ReactPaginate from 'react-paginate';
import { Container, Row, Col } from "react-grid-system";
const axios = require("axios");


class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "All",
      properties: [],
      city: [],
      currentPage: 0,
      noMoreResult: true,
      total_pages: 0,
      pageStart: 0,
      pageEnd: 5
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
    this.getProperties();
  }
  pageChange = (x) => {
    this.setState(
      {
        currentPage: x.selected
      },
      () => {
        this.getProperties();
      }
    );
  };

  getProperties() {
    const headers = {
      "device-unique-id": "8hfjdhf84r84kdhdh"
    };
    const Offset = this.state.currentPage * 9;
    const params =
      this.state.selectedOption === "All"
        ? {
            city: null,
            offset:Offset,
            total: 9
          }
        : {
            city: this.state.selectedOption,
            offset: Offset,
            total: 9
          };
    axios
      .get(`https://services.guesthouser.com/v1.6/prive/listings`, {
        headers,
        params
      })
      .then(response => {
        //console.log("sfs", response);
        this.setState(
          {
            properties: response.data.data.properties,
            city: response.data.data.city,
            total_pages: parseInt(response.data.data.total_count / 9),
            noMoreResult: false
          },
          () => {
            console.log(this.state.total_pages);
            window.scrollTo({top: 0, behavior: 'smooth'});
          }
        );
      })
      .catch(error => {
        console.log(error);
      });
  }

  handleChange(event) {
    this.setState(
      {
        selectedOption: event.target.value,
        noMoreResult: true,
        currentPage: 1,
        pageStart: 0,
        pageEnd: 5
      },
      () => {
        this.getProperties();
      }
    );
  }

  handleSubmit(event) {
    event.preventDefault();
  }

  render() {
    const this_el = this;
    const ellipsis = {
      WebkitBoxOrient: "vertical"
    };
    return (
      <div>
        <div className="cenemagraph properties">
          {/******************* why us? ********************/}
          <div className="property_slogan">
            <h1>Some of our Signature properties</h1>
            <h3>We provide with some of the best properties pan India</h3>
          </div>
          <form className="form">
            <input type="text" name="search" placeholder="Search.." />
          </form>
        </div>
        <Container
          className="wrapper properties"
          fluid
          style={{ maxWidth: "1200px" }}
        >
          <Row className="tile-container">
            <Col sm={12}>
              <form onSubmit={this.handleSubmit}>
                <div className="styled-select blue semi-square">
                  <select
                    className="form-control"
                    onChange={this.handleChange}
                    required
                  >
                    <option value="" selected disabled>
                      Filter by location
                    </option>
                    <option value="All">All</option>
                    {this.state.city.map((option, index) => (
                      <option key={index} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </Col>

            {this_el.state.properties
              .filter(
                x =>
                  this.state.selectedOption === "All" ||
                  x.city.includes(this.state.selectedOption)
              )
              .map((item, index) => {
                return (
                  <Col sm={6} md={4} key={index}>
                    <a
                      rel="noopener noreferrer"
                      href={item.url}
                      target="_blank"
                    >
                      <div className="view-box">
                        <div
                          className="image"
                          style={{
                            backgroundImage: `url(${
                              item.properties_images[0].image
                            })`
                          }}
                        />
                        <div className="content">
                          <div className="heading" style={ellipsis}>
                            {item.property_title}
                          </div>
                          <div className="subheading">
                            {item.area}, {item.state}
                          </div>
                        </div>
                      </div>
                    </a>
                  </Col>
                );
              })}
            {this.state.noMoreResult && (
              <div class="spinner">
                <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    class="length"
                    fill="none"
                    stroke-width="8"
                    stroke-linecap="round"
                    cx="33"
                    cy="33"
                    r="28"
                  />
                </svg>
                <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    fill="none"
                    stroke-width="8"
                    stroke-linecap="round"
                    cx="33"
                    cy="33"
                    r="28"
                  />
                </svg>
                <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    fill="none"
                    stroke-width="8"
                    stroke-linecap="round"
                    cx="33"
                    cy="33"
                    r="28"
                  />
                </svg>
                <svg viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle
                    fill="none"
                    stroke-width="8"
                    stroke-linecap="round"
                    cx="33"
                    cy="33"
                    r="28"
                  />
                </svg>
              </div>
            )}
          </Row>
          <Row>
            <Col sm={12} md={12} className="text-center">
              {this.state.properties.length > 0 && (
                <ReactPaginate
                previousLabel={'❮'}
                nextLabel={'❯'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={this.state.total_pages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={5}
                onPageChange={this.pageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
                // <div className="pagination">
                //   <span
                //     className={this.state.currentPage === 1 ? "disabled" : null}
                //     onClick={this.offsetMinus}
                //   >
                //     ❮
                //   </span>
                //   {[...Array(this.state.total_pages).keys()]
                //     .slice(this.state.pageStart, this.state.pageEnd)
                //     .map((item, index) => (
                //       <span
                //         href="#"
                //         className={`${this.state.currentPage === item + 1 &&
                //           "active"}`}
                //         onClick={() => this.pageChange(item + 1)}
                //       >
                //         {item + 1}
                //       </span>
                //     ))}
                //   <span
                //     className={
                //       this.state.currentPage === this.state.total_pages
                //         ? "disabled"
                //         : null
                //     }
                //     onClick={this.offsetPlus}
                //   >
                //     ❯
                //   </span>
                // </div>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default Properties;
