/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from "react";
import "../assets/sass/main.scss";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Modal from "react-modal";
import { Row, Col, Hidden } from "react-grid-system";
import Home from "./../container/home/";
import Properties from "./../container/properties/";
import Header from "../component/header";
import Footer from "../component/footer";
import popupImage from "../assets/img/popup-image.png";
import Form from "../component/form";

class App extends Component {
  state = {
    refs: [],
    isModalOpen: false,
    success: false,
    items: ["Home", "Why us?", "How?", "Portfolio Homes", 'Testimonials', "Team"]
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
  };

  closeModal = () => {
    this.setState({ isModalOpen: false, success: false });
    document.body.style.overflow = "unset";
    document.body.style.position = "unset";
  };

  getRefs = refs => {
    this.setState({
      refs
    });
  };

  render() {
    return (
      <Router>
        <div className="App">
          <Header
            items={this.state.items}
            myRefs={this.state.refs}
            openModal={this.openModal}
          />
          <Switch>
            <Route
              exact
              path="/"
              render={props => (
                <Home
                  items={this.state.items.map(x =>
                    x
                      .split(" ")
                      .join("")
                      .toLowerCase()
                  )}
                  getRefs={this.getRefs}
                  openModal={this.openModal}
                  {...props}
                />
              )}
            />
            <Route exact path="/properties" component={Properties} />
          </Switch>
          <Footer openModal={this.openModal} />
        </div>

        <Modal
          isOpen={this.state.isModalOpen}
          className="Modal"
          overlayClassName="Overlay"
        >
          {this.state.success ? (
            <Row>
              <Col sm={12} className="form_success">
                {/* <a
                  // eslint-disable-next-line no-script-url
                  href="Javascript:void(0)"
                  className="close"
                  onClick={this.closeModal}
                /> */}
                <div className="success">
                  Thanks for submitting! <br /> Someone from our team will reach
                  out to you
                  <button
                    type="button"
                    onClick={this.closeModal}
                    className="button"
                  >
                    Done
                  </button>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Hidden xs>
                <Col sm={6}>
                  <img src={popupImage} />
                </Col>
              </Hidden>
              <Col xs={12} sm={6} className="form_wrapeer">
                <a
                  href="Javascript:void(0)"
                  className="close"
                  onClick={this.closeModal}
                />
                <Form
                  updateSuccessState={success => this.setState({ success })}
                />
              </Col>
            </Row>
          )}
        </Modal>
      </Router>
    );
  }
}

export default App;
