import React, { Component } from "react";
import {  withRouter } from "react-router-dom";
import { Container } from "react-grid-system";
class footer extends Component {
  render() {
    return (
      <div className="footer">
         <Container  fluid style={{ maxWidth: '1200px',paddingLeft:'0',paddingRight:'0' }}>
            <div className="heading">Let’s make it work, Properly!</div>
            <div className="subheading">Have a second home that can be somebody’s holiday home?</div>
            <button onClick={this.props.openModal} >Drop us a line</button>
            <ul>
              <li><a href="tel:+919873217831"><span className="icon mobile"/>+919873217831</a></li>
              <li><a href="mailto:manage@properly.com"><span className="icon email"/>hk@guesthouser.com</a></li>
            </ul>
        </Container>
      </div>
    );
  }
}
export default withRouter(footer);
