import React, { Component } from "react";
import AWS from "aws-sdk";
import CryptoJs from "crypto-js";

const key = "guesthouser";
const encryptedaccessKeyId =
  "U2FsdGVkX19NpX35KSHTQ/bina9hn7zL5b3Qj4UxdRu2eQ75/rYfVXJ2LHbLHYSI";
const encryptedsecretAccessKey =
  "U2FsdGVkX188IONi5X3kc5UDOTMKs3PtuzVDa3EY7MXP0kumv7AQXR45LvXjan3ILx8uaFGA3S4/6qYNi+O7QQ==";

AWS.config.credentials = {
  accessKeyId: CryptoJs.AES.decrypt(encryptedaccessKeyId, key).toString(
    CryptoJs.enc.Utf8
  ),
  secretAccessKey: CryptoJs.AES.decrypt(encryptedsecretAccessKey, key).toString(
    CryptoJs.enc.Utf8
  ),
  region: "ap-south-1"
};

const lambda = new AWS.Lambda({
  region: "ap-south-1",
  apiVersion: "2015-03-31"
});

class Form extends Component {
  constructor() {
    super();
    this.state = {
      fields: {
        username: "",
        emailid: "",
        mobileno: "",
        message: ""
      },
      errors: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.submituserRegistrationForm = this.submituserRegistrationForm.bind(
      this
    );
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }

  submituserRegistrationForm(e) {
    e.preventDefault();
    this.validateForm(() => {
      if (
        Object.keys(this.state.errors).filter(
          x => this.state.errors[x] !== undefined
        ).length === 0
      ) {
        this.props.updateSuccessState(true);

        const txt = Object.keys(this.state.fields).reduce(
          (acc, x) => `${acc} <br> ${x}: ${this.state.fields[x]}`,
          ""
        );

        console.log(txt);

        const emailList = [
          "business@properly.com"
        ];

        emailList.map(to => {
          const pullParams = {
            FunctionName: "sendemail",
            Payload: JSON.stringify({
              from: "support@guesthouser.com",
              to: to,
              subject: "Properly Business",
              html: txt.replace("undefined:", "")
            })
          };

          lambda.invoke(pullParams, function(err, data) {
            if (err) {
              prompt(err);
            } else {
              console.log(data);
            }
          });
        });
      }
    });
  }

  validateUserName = cb => {
    const {
      fields: { username }
    } = this.state;

    if (username.length !== 0) {
      if (!username.match(/^[a-zA-Z ]*$/)) {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              username: "*Please enter alphabet characters only."
            }
          },
          cb
        );
      } else {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              username: undefined
            }
          },
          cb
        );
      }
    } else {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            username: "*Please enter your full name"
          }
        },
        cb
      );
    }
  };

  validateEmailID = cb => {
    const {
      fields: { emailid }
    } = this.state;

    if (emailid.length !== 0) {
      const pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(emailid)) {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              emailid: "*Please enter valid email-ID."
            }
          },
          cb
        );
      } else {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              emailid: undefined
            }
          },
          cb
        );
      }
    } else {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            emailid: "*Please enter your email-ID"
          }
        },
        cb
      );
    }
  };

  validateMobileNo = cb => {
    const {
      fields: { mobileno }
    } = this.state;

    if (mobileno.length !== 0) {
      if (!mobileno.match(/^[0-9]{10}$/)) {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              mobileno: "*Please enter valid mobile no."
            }
          },
          cb
        );
      } else {
        this.setState(
          {
            errors: {
              ...this.state.errors,
              mobileno: undefined
            }
          },
          cb
        );
      }
    } else {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            mobileno: "*Please enter your mobile no."
          }
        },
        cb
      );
    }
  };

  validateMessage = cb => {
    const {
      fields: { message }
    } = this.state;
    if (message.length === 0) {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            message: "*Please enter your message."
          }
        },
        cb
      );
    } else {
      this.setState(
        {
          errors: {
            ...this.state.errors,
            message: undefined
          }
        },
        cb
      );
    }
  };

  validateForm = cb => {
    this.validateMessage(() =>
      this.validateEmailID(() =>
        this.validateMobileNo(() => this.validateUserName(cb))
      )
    );
  };

  onBlurShowMessage(e) {
    if (e.target.name === "username") {
      this.validateUserName();
    }
    if (e.target.name === "emailid") {
      this.validateEmailID();
    }
    if (e.target.name === "mobileno") {
      this.validateMobileNo();
    }
    if (e.target.name === "message") {
      this.validateMessage();
    }
  }

  onFocusHideMessage(e) {
    if (e.target.name === "username") {
      this.setState({
        errors: {
          ...this.state.errors,
          username: undefined
        }
      });
    }
    if (e.target.name === "emailid") {
      this.setState({
        errors: {
          ...this.state.errors,
          emailid: undefined
        }
      });
    }
    if (e.target.name === "mobileno") {
      this.setState({
        errors: {
          ...this.state.errors,
          mobileno: undefined
        }
      });
    }
    if (e.target.name === "message") {
      this.setState({
        errors: {
          ...this.state.errors,
          message: undefined
        }
      });
    }
  }

  render() {
    return(
      <form
        method="post"
        name="userRegistrationForm"
        onSubmit={this.submituserRegistrationForm}
      >
        <div className="input-wrap">
          <input
            type="text"
            name="username"
            autoComplete="false"
            value={this.state.fields.username}
            onFocus={e => this.onFocusHideMessage(e)}
            onBlur={e => this.onBlurShowMessage(e)}
            onChange={this.handleChange}
            placeholder="Your full name"
          />
          <span className="errorMsg">{this.state.errors.username}</span>
        </div>
        <div className="input-wrap">
          <input
            type="text"
            name="emailid"
            value={this.state.fields.emailid}
            onFocus={e => this.onFocusHideMessage(e)}
            onBlur={e => this.onBlurShowMessage(e)}
            onChange={this.handleChange}
            placeholder="Email"
          />
          <span className="errorMsg">{this.state.errors.emailid}</span>
        </div>
        <div className="input-wrap">
          <input
            type="text"
            name="mobileno"
            value={this.state.fields.mobileno}
            onFocus={e => this.onFocusHideMessage(e)}
            onBlur={e => this.onBlurShowMessage(e)}
            onChange={this.handleChange}
            placeholder="Contact number"
          />
          <span className="errorMsg">{this.state.errors.mobileno}</span>
        </div>
        <div className="input-wrap">
          <label className="textara-level">Your message</label>
          <textarea
            name="message"
            onChange={this.handleChange}
            value={this.state.fields.message}
            onFocus={e => this.onFocusHideMessage(e)}
            onBlur={e => this.onBlurShowMessage(e)}
          />
          <span className="errorMsg error">{this.state.errors.message}</span>
        </div>

        <button type="submit" className="button">
          Submit
        </button>
      </form>
    );
  }
}

export default Form;
